import { toast } from 'react-toastify'
import { getToken, getAuthTokenAuthorization, obterRefreshToken, handleLogout, getTokenExpiration, getRefreshTokenExpiration } from '@utils'
import CustomToast from '../components/custom-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AlertCircle } from 'react-feather'
import moment from 'moment'
import { isUserLoggedIn } from 'utility/Utils'

const MySwal = withReactContent(Swal)

const API_URL = `${process.env.REACT_APP_API_URL}/`

const envirommentType = `${process.env.REACT_APP_ENV}`

let alertedUnauthorized = false

let alertedMultipleLogins = false

let nextCheck = Math.max(Math.random() * 60000, 1000)

const setHeaders = (content_type, check) => {
  const headers = new Headers()

  if (content_type !== '') headers.append('Content-Type', content_type)

  if (check) headers.append('Authorization', `Bearer ${getToken()}`)

  if (envirommentType === 'production') {
    if (check) headers.append('AuthTokenAuthorization', `${getAuthTokenAuthorization()}`)
  }

  return headers
}

const handleAlertLogoff = async () => {
  return MySwal.fire({
    title: '<strong>Atenção!</strong>',
    text: 'Sua sessão expirou, deseja continuar no IntegraDoor?',
    iconHtml: <AlertCircle size={80} />,
    icon: 'warning',
    padding: '4px 0 43px 0',
    width: '512px',
    showCancelButton: true,
    confirmButtonText: 'Permanecer',
    cancelButtonText: 'Sair',
    customClass: {
      confirmButton: 'btn btn-primary ms-1',
      cancelButton: 'btn btn-outline-primary'
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  }).then(async function (result) {
    if (result.value) {
      await obterRefreshToken()
      alertedUnauthorized = false
    } else {
      handleLogout()
      window.location.href = '/login'
      alertedUnauthorized = false
    }
  })
}

const handleAlertMoreThanOneLogOn = async () => {
  return MySwal.fire({
    title: '<strong>Atenção!</strong>',
    text: 'Observamos que sua conta foi acessada de mais de um local simultaneamente. Para garantir a segurança de seus dados e manter a integridade do sistema, você será desconectado dessa sessão.',
    iconHtml: <AlertCircle size={80} />,
    icon: 'warning',
    padding: '4px 0 43px 0',
    width: '512px',
    showCancelButton: false,
    confirmButtonText: 'Ok',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  }).then(function (result) {
    if (result.value) {
      handleLogout()
      window.location.href = '/login'
      alertedMultipleLogins = false
    }
  })
}

const handleAlertLogoffDefinitive = async () => {
  return MySwal.fire({
    title: '<strong>Atenção!</strong>',
    text: 'Sua sessão expirou e será necessário realizar um novo login!',
    iconHtml: <AlertCircle size={80} />,
    icon: 'warning',
    padding: '4px 0 43px 0',
    width: '512px',
    showCancelButton: true,
    confirmButtonText: 'Ok',
    showCancelButton: false,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  }).then(function (result) {
    if (result.value) {
      handleLogout()
      window.location.href = '/login'
      alertedMultipleLogins = false
    }
  })
}

const invalidTokens = () => {
  if (moment(moment(new Date()).toISOString()).isAfter(moment(getTokenExpiration()))) {
    if (moment(moment(new Date()).toISOString()).isAfter(moment(getRefreshTokenExpiration()))) {
      if (!alertedUnauthorized) {
        alertedUnauthorized = true
        handleAlertLogoffDefinitive()
      }
      return true
    }
    if (!alertedUnauthorized) {
      alertedUnauthorized = true
      handleAlertLogoff()
    }
    return true
  }
  return false
}

const getResp = async (label, resp) => {
  // const history = useHistory()
  if (resp) {
    if (resp.status !== 204) {
      let json = null
      try {
        json = await resp.json()
      } catch (error) {
        console.error(error)
      }
      if (resp.status > 300) {

        if (resp.status === 401) {
          if (!alertedUnauthorized) {
            alertedUnauthorized = true
            handleAlertLogoff()
          }
          return
        }

        if (resp.status === 406) {
          if (!alertedMultipleLogins) {
            alertedMultipleLogins = true
            handleAlertMoreThanOneLogOn()
          }
          return
        }


        if (resp.status === 500) {
          return toast.error(
            <CustomToast
              title='Erro!'
              message='Ocorreu um erro inesperado, favor tentar novamente ou contactar o suporte!'
            />
          )
        }

        if (json && json.mensagens && json.mensagens !== '') {

          if (json.mensagens.some(mensagem => mensagem.includes("E001"))) {
            return json
          }

          if (!toast.isActive('toastDefaultError')) {
            toast.error(
              <CustomToast
                title='Erro!'
                message={`${json.mensagens}`}
              />,
              { autoClose: true, toastId: 'toastDefaultError' }
            )
          } else {
            toast.update(
              <CustomToast
                title='Erro!'
                message={`${json.mensagens}`}
              />,
              { autoClose: true, toastId: 'toastDefaultError' }
            )
          }

          if (json.ValidationErrors) {
            return json
          }
        }
      } else if (json) return json
    } else {
      return { message: 'success' }
    }
  }
  return null
}

export const getReq = async (label, url, check, version = 'v1') => {
  if (invalidTokens()) return
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'GET',
        headers
      }

      const url_completa = `${API_URL}${version}${url}`

      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) {
        handleAlertLogoff()
        return
      }

      if (resp.status === 406) {
        if (!alertedMultipleLogins) {
          alertedMultipleLogins = true
          handleAlertMoreThanOneLogOn()
        }
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao carregar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const postReq = async (label, url, body, check, version = 'v1') => {
  if (invalidTokens()) return
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      }

      const url_completa = `${API_URL}${version}${url}`

      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) {
        handleAlertLogoff()
        return
      }

      if (resp.status === 406) {
        if (!alertedMultipleLogins) {
          alertedMultipleLogins = true
          handleAlertMoreThanOneLogOn()
        }
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao criar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const putReq = async (label, url, body, check, version = 'v1') => {
  if (invalidTokens()) return
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
      }

      const url_completa = `${API_URL}${version}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) {
        handleAlertLogoff()
        return
      }

      if (resp.status === 406) {
        if (!alertedMultipleLogins) {
          alertedMultipleLogins = true
          handleAlertMoreThanOneLogOn()
        }
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao atualizar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
}

export const deleteReq = async (label, url, check, version = 'v1') => {
  if (invalidTokens()) return
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'DELETE',
        headers
      }

      const url_completa = `${API_URL}${version}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) {
        handleAlertLogoff()
        return
      }

      if (resp.status === 406) {
        if (!alertedMultipleLogins) {
          alertedMultipleLogins = true
          handleAlertMoreThanOneLogOn()
        }
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao remover ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const sendFile = async (label, url, body, check, version = 'v1') => {
  if (invalidTokens()) return
  if (navigator.onLine) {
    try {
      const headers = setHeaders('', check)

      const reqParams = {
        method: "POST",
        headers,
        body
      }

      const url_completa = `${API_URL}${version}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) {
        handleAlertLogoff()
        return
      }

      if (resp.status === 406) {
        if (!alertedMultipleLogins) {
          alertedMultipleLogins = true
          handleAlertMoreThanOneLogOn()
        }
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao enviar imagem do ${label}: ${e} `)
    }
  }

  function rememberTSAndReload(serverTS) {
    localStorage.setItem('lastTS', serverTS)
    // the next line should be uncommented for production purposes
    if (envirommentType === 'production') location.reload(true)
  }

  async function handleUpdates() {
    const resp = await fetch('/timestamp.txt')
    if (!resp.ok) {
      console.error('Erro ao buscar o timestamp.')
      rememberTSAndReload()
      return
    }
    const serverTS = await resp.text()
    const clientTS = localStorage.getItem('lastTS')

    if (clientTS !== serverTS) {
      if (isUserLoggedIn()) {
        // Show alert
        MySwal.fire({
          title: 'Atenção!',
          text: 'Novas atualizações disponíveis. Deseja recarregar a página agora?',
          iconHtml: <AlertCircle size={80} />,
          icon: 'warning',
          padding: '4px 0 43px 0',
          width: '512px',
          showCancelButton: true,
          confirmButtonText: 'Sim, recarregar!',
          cancelButtonText: 'Mais tarde',
          customClass: {
            confirmButton: 'btn btn-primary ms-1',
            cancelButton: 'btn btn-outline-primary'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.isConfirmed) {
            rememberTSAndReload(serverTS)
          }
        })
      } else {
        MySwal.fire({
          title: 'Atenção!',
          text: 'Atualizações detectadas! Estamos recarregando a página para aplicar as modificações.',
          iconHtml: <AlertCircle size={80} />,
          padding: '4px 0 43px 0',
          width: '512px',
          icon: 'warning',
          timer: 3000,
          showConfirmButton: 'Ok',
          showCancelButton: false
        }).then(rememberTSAndReload(serverTS))
      }
    } else {
      setTimeout(handleUpdates, nextCheck)
      // 43200000 = 12 hours in ms, this should be configurable per env
      nextCheck = Math.min(2 * nextCheck, 43200000)
    }
  }
  handleUpdates()
}